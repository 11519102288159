import fileUpdate from '../components/file-update'
import companyEvaluation from '../components/company-evaluation.vue'
// import BaseSelect from '@/components/common/base-select/base-select.vue'
import SelectEnterprise from '../components/select-enterprise.vue'
import selectPersonnel from '../components/select-personnel.vue'
import InterviewAccessory from '../components/interview-accessory.vue'
import SupperlierTesting from '@/pages/basic-data-management/supplier-management/components/supperlier-testing.vue'
import SupplierCascader from '@/pages/basic-data-management/supplier-management/components/supplier-cascader'
import Amountinput from '@/components/input/amount-input/amount-input'
import SelectUser from '../components/select-user.vue'
import NewSelectUser from '../components/new-select-user.vue'
import { getDictLists } from '@/filters/fromDict'
import enterprise from '../components/enterprise.vue'
import {
  formatDate
} from '@/utils/auth/common'
const validatorIntervieweeInfos = (content) => {
  return (rule, value, callback) => {
    if (content.intervieweeInfos && content.intervieweeInfos.length > 0) {
      callback()
    } else {
      callback(
        new Error('请选择受访人员')
      )
    }
  }
}
const validatorInterviewersInfos = (content) => {
  return (rule, value, callback) => {
    if (content.interviewersInfos && content.interviewersInfos.length > 0) {
      callback()
    } else {
      callback(
        new Error('请选择访谈人员')
      )
    }
  }
}
export const interviewBasicConfig = (content) => {
  const startOptions = {
    disabledDate (time) {
      return time.getTime() <= new Date('1989-12-30')
    }
  }
  return [
    {
      label: '企业名称',
      prop: 'enterpriseName',
      attrs: {
        placeholder: '请输入',
        enterpriseLabel: content.currentData.enterpriseLabel
      },
      tag: enterprise,
      style: 'overflow: hidden;'
    },
    {
      label: '简称',
      prop: 'enterpriseShortName',
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '成立时间',
      prop: 'foundTime',
      type: 'Date',
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '所在城市',
      prop: 'city',
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '公司电话',
      prop: 'phoneNo',
      attrs: {
        placeholder: '请输入',
        pickerOptions: startOptions,
        clearable: true
      }

    }, {
      label: '统一社会信用代码',
      prop: 'creditCode',
      attrs: {
        isRange: true,
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '至',
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        clearable: true
      }

    }, {
      label: '公司地址',
      prop: 'address',
      attrs: {
        placeholder: '请输入',
        maxLength: 100
      }
    },
    {
      label: '公司传真',
      prop: 'fax',
      attrs: {
        placeholder: '请输入',
        maxLength: 100
      }
    },
    {
      label: '工商注册日期',
      prop: 'registerTime',
      type: 'Date',
      attrs: {
        placeholder: '请输入',
        maxLength: 100
      }
    },
    {
      label: '注册资金(元)',
      prop: 'registerMoney',
      type: 'Amount',
      attrs: {
        placeholder: '请输入',
        maxLength: 100
      }
    },
    {
      label: '企业类型',
      prop: 'enterpriseLabel',
      attrs: {
        placeholder: '请输入',
        maxLength: 100
      }
    }
  ]
}
// 企业基本信息
export const enterpriseConfig = (content) => {
  return [{
    label: '企业名称',
    prop: 'enterpriseName',
    rules: [{
      required: true,
      title: '供应商名称',

      trigger: ['change', 'blur']
    }],

    span: 6

  },
  {
    label: '法人代表',
    prop: 'legalRepresentative',
    rules: [{
      required: true,
      title: '法人代表',

      trigger: ['change', 'blur']
    }],
    span: 6,
    attrs: {
      placeholder: '请输入'
    }
  },
  {
    label: '成立时间',
    prop: 'foundTime',
    type: 'Date',
    span: 6,
    attrs: {
      placeholder: '请选择'
    }
  },
  {
    label: '所在城市',
    prop: 'locationCity',

    span: 6
  },
  {
    label: '公司电话',
    prop: 'phoneNo',

    span: 6,
    attrs: {
      placeholder: '请输入',
      maxlength: 13
    }
  }, {
    label: '统一社会信用代码',
    prop: 'creditCode',

    span: 6,
    attrs: {
      maxlength: 18,
      placeholder: '请输入'
    }
  }, {
    label: '公司地址',
    prop: 'address',
    rules: [{
      required: false,
      message: '请输入公司地址',
      trigger: ['change', 'blur']
    }],
    span: 6,
    attrs: {
      placeholder: '请输入'
    }
  }, {
    label: '公司传真',
    prop: 'fax',
    rules: [{
      required: false,
      message: '请输入',
      trigger: ['change', 'blur']
    }],
    span: 6,
    attrs: {
      maxlength: 13,
      placeholder: '请输入'
    }
  }, {
    label: '工商注册日期',
    prop: 'registerTime',
    type: 'Date',
    span: 6,
    attrs: {
      placeholder: '请输入'
    }
  }, {
    label: '注册资金(元)',
    prop: 'registerMoney',
    span: 6,
    rules: [{
      required: true,
      message: '请输入注册资金',
      trigger: ['change', 'blur']
    }],

    attrs: {
      placeholder: '请输入',
      type: 'G'
    }
  }, {
    label: '实控人',
    prop: 'actualController',
    rules: [{
      required: false,
      message: '请输入实控人',
      trigger: ['change', 'blur']
    }],
    span: 6,
    attrs: {
      placeholder: '请输入'
    }
  },
  {
    label: '简称',
    prop: 'enterpriseShortName',

    span: 6,
    attrs: {
      placeholder: '请输入'
    }
  },
  {
    label: '简码',
    prop: 'enterpriseShortCode',

    span: 6,
    attrs: {
      placeholder: '请输入',
      maxlength: 5
    },
    on: {

    }
  }
    // {
    //   label: 'EAS企业别名',
    //   prop: 'easEnterpriseAlias',
    //   rules: [{
    //     required: false,

    //     trigger: ['change', 'blur']
    //   }],
    //   span: 6,
    //   attrs: {
    //     placeholder: '请输入',
    //     maxlength: 50
    //   }

    // },
    // {
    //   label: 'EAS统一社会信用代码',
    //   prop: 'easCreditCode',

    //   span: 6,
    //   attrs: {
    //     placeholder: '请输入',
    //     maxlength: 50
    //   }

  // }
  ]
}
// 账户信息
export const accountcolumns = (content) => {
  return [
    {
      label: '户名',
      prop: 'account',
      HiddenOverflow: true
    },
    {
      label: '开户行',
      prop: 'bankName',
      HiddenOverflow: true
    },
    {
      label: '账号',
      prop: 'accountNo',
      HiddenOverflow: true
    },
    {
      label: '账户类型',
      text: '选择',
      prop: 'accountType',
      HiddenOverflow: true
    }

  ]
}
// 人员信息
export const personnelcolumns = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      label: '姓名',
      prop: 'personnerName',
      HiddenOverflow: true
    },
    {
      label: '身份证',
      prop: 'idCard',
      HiddenOverflow: true

    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      HiddenOverflow: true
    },
    {
      label: '职位',
      prop: 'dutiesName',
      HiddenOverflow: true

    },
    {
      label: '家庭地址',
      prop: 'address',
      HiddenOverflow: true

    },
    {
      label: '人员类别',
      prop: 'dictId',
      HiddenOverflow: true
    }
    // {
    //   label: '是否EAS同步',
    //   prop: 'easUser',
    //   HiddenOverflow: true,
    //   formatter: row => {
    //     return row.easUser === 0 || !row.easUser ? '否' : '是'
    //   }
    // }

  ]
}
// 人员关系子表格信息
export const personnelRelationColumns = (content) => {
  return [

    {
      label: '姓名',
      prop: 'personnalName',
      HiddenOverflow: true
    },
    {
      label: '身份证',
      prop: 'idCard',
      HiddenOverflow: true

    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      HiddenOverflow: true
    },
    {
      label: '地址',
      prop: 'address',
      HiddenOverflow: true
    },
    {
      label: '关系',
      prop: 'contactType',
      HiddenOverflow: true,
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType)
        return obj ? obj.dictName : '-'
      }
    }

  ]
}
// 附件信息
export const filefromconfig = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      label: '序号',
      prop: 'index',
      HiddenOverflow: true
    },
    {
      label: '文件类型',
      prop: 'configItemName',
      HiddenOverflow: true

    },
    {
      label: '操作',
      prop: 'action',
      HiddenOverflow: true

    }
  ]
}
export const fileList = (content) => {
  return [
    {
      label: '文件名称',
      prop: 'fileName'

    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 企业信息变更记录
export const changeRecordColumn = (content) => {
  return [{
    label: '变更事项',
    prop: 'changeItem',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '变更前',
    prop: 'contentBefore',
    'show-overflow-tooltip': true,
    minWidth: '120px',
    align: 'left'
  },
  {
    label: '变更后',
    prop: 'contentAfter',
    minWidth: '120px',
    'show-overflow-tooltip': true
  },
  {
    label: '变更时间',
    prop: 'changeTime',
    width: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.changeTime, 'YY-MM-DD')
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    width: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.createTime, 'YY-MM-DD')
    }

  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '120px',
    'show-overflow-tooltip': true
    // align: 'left',

  },
  {
    label: '数据来源',
    prop: 'dataSources',
    width: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => row.dataSources === 'TIANYANCHA' ? '天眼查同步' : '用户添加'
  }

  ]
}
// 被执行人
export const executorRecordColumn = (content) => {
  return [{
    label: '被执行人名称',
    prop: 'beExecutorName',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '身份证号/组织机构代码',
    prop: 'partyCardNum',
    'show-overflow-tooltip': true,
    minWidth: '130px'

  },
  {
    label: '执行标的（元）',
    prop: 'execMoney',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    formatter: row => {
      return (row.execMoney ? parseFloat(row.execMoney).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
    }
  },
  {
    label: '执行法院',
    prop: 'execCourtName',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '创建时间',
    prop: 'createTime',
    width: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.createTime, 'YY-MM-DD')
    }

  },
  {
    label: '数据来源',
    prop: 'dataSources',
    width: '120px',
    formatter: row => {
      return row.dataSources === 'TIANYANCHA' ? '天眼查同步' : '用户添加'
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '120px'
  },
  {
    label: '附件',
    prop: 'fileId',
    minWidth: '240px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  }

  ]
}
// 限制消费变更记录
export const consumptionRecordColumn = (content) => {
  return [{
    label: '企业信息',
    prop: 'companyInfo',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '限制消费者名称',
    prop: 'restrictConsumerName',
    'show-overflow-tooltip': true,
    minWidth: '120px',
    align: 'left'
  },
  {
    label: '立案时间',
    prop: 'caseCreateTime',
    minWidth: '120px',
    formatter: row => {
      return formatDate(row.caseCreateTime, 'YY-MM-DD')
    },
    'show-overflow-tooltip': true
  },
  {
    label: '申请人信息',
    prop: 'applicantInfo',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'right'

  },
  {
    label: '发布日期',
    prop: 'publishDate',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.publishDate, 'YY-MM-DD')
    }

  },
  {
    label: '数据来源',
    prop: 'dataSources',
    width: '120px',
    formatter: row => {
      return row.dataSources === 'TIANYANCHA' ? '天眼查同步' : '用户添加'
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '120px'
  },
  {
    label: '附件',
    prop: 'fileId',
    minWidth: '240px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  }

  ]
}
// 访谈纪要搜索
export const searchConfig = (content) => {
  return [{
    label: '纪要发表日期',
    prop: 'addTime',
    attrs: {
      placeholder: '请选择',
      type: 'daterange',
      'range-separator': '至',
      'start-placeholder': '开始日期',
      'end-placeholder': '结束日期',
      'value-format': 'yyyy-MM-dd'
    },
    rules: [
      { required: false, message: '请选择纪要发表日期', trigger: ['change, blur'] }
    ],
    tag: 'el-date-picker'

  }, {
    label: '关键字',
    prop: 'interviewDetailsKw',
    attrs: {
      placeholder: '请输入'

    },
    rules: [
      { required: false, message: '请输入', trigger: ['change, blur'] }
    ]

  }]
}
// 新的访谈记录
export const newConfig = (content) => {
  const startOptions = {
    disabledDate (time) {
      return time.getTime() > new Date() || time.getTime() <= new Date('1989-12-30')
    }
  }
  console.log(content.InterviewData, '选择后')
  return [{
    label: '访谈日期',
    prop: 'interviewDate',
    attrs: {
      placeholder: '请选择',
      type: 'date',
      editable: false,
      'value-format': 'yyyy-MM-dd',
      pickerOptions: startOptions
    },
    rules: [
      { required: true, message: '请选择访谈日期', trigger: ['change'] }
    ],
    span: 8,
    tag: 'el-date-picker'

  }, {
    label: '受访人员',
    prop: 'intervieweeInfos',
    attrs: {
      placeholder: '请选择',
      userData: content.InterviewData.intervieweeInfos || []
    },
    rules: [
      // {
      //   required: true,
      //   message: '请选择受访人员',
      //   trigger: 'blur'
      // },
      { required: true, validator: validatorIntervieweeInfos(content.InterviewData), trigger: ['change', 'blur'] }
    ],
    span: 8,
    tag: NewSelectUser,
    on: {
      chose (data) {
        content.$set(content.InterviewData, 'intervieweeInfos', data)
        content.$refs.formDataList.validateField('intervieweeInfos')
      }
    }
  },
  {
    label: '访谈人员',
    prop: 'interviewersInfos',
    attrs: {
      placeholder: '请选择',
      userData: content.InterviewData.interviewersInfos || []
    },
    rules: [
      { required: true, validator: validatorInterviewersInfos(content.InterviewData), trigger: ['change', 'blur'] }
    ],
    span: 8,
    tag: SelectUser,
    on: {
      chose (data) {
        content.$set(content.InterviewData, 'interviewersInfos', data)
        content.$refs.formDataList.validateField('interviewersInfos')
      }
    }

  },
  {
    label: '访谈详情',
    prop: 'interviewDetails',
    attrs: {
      placeholder: '请输入',
      type: 'textarea',
      rows: 10,
      maxlength: 4000,
      'show-word-limit': true
    },
    rules: [
      { required: true, message: '请输入访谈详情', trigger: ['blur', 'change'] }
    ],
    span: 24

  }
  ]
}
// 受访信息
export const respondentsConfig = (content, index) => { // index 数组中的第几个
  return [
    {
      label: '受访企业名称',
      prop: 'enterpriseName',
      attrs: {
        placeholder: '请选择',
        options: [
          { name: '供应商', id: 1 },
          { name: '项目公司', id: 2 }
        ],
        selectedField: ['id', 'name']
      },
      rules: [
        { required: true, message: '请选择受访企业', trigger: ['change, blur'] }
      ],
      tag: SelectEnterprise,
      on: {
        change (data) {
          console.log(data, index)
          content.changeEnterprise(data, index)
        }
      }
    },
    {
      label: '受访人员姓名',
      prop: 'name',
      attrs: {
        placeholder: '请选择',
        options: [
          { name: '112312', id: 1 }
        ]
      },
      rules: [
        { required: true, message: '请选择受访人员', trigger: ['change, blur'] }
      ],
      tag: selectPersonnel
    }
  ]
}
// 选择企业
export const selectPersonnelConfig = (content) => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '80'
    },
    {
      label: '受访企业名称',
      prop: 'xxxx'
    },
    {
      label: '受访人员姓名',
      prop: 'xxxx'
    },
    {
      label: '受访人员职务',
      prop: 'xxxx'
    }
  ]
}
// 访谈详情-供应商
export const interviewDetilsConfig = (content, index) => {
  // 选中的访谈范围
  const checkList = content.interviewRecordData[index].checkList
  return [
    {
      label: '公司主体',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('公司主体')
    },
    {
      label: '经营情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('经营情况')
    },
    {
      label: '金地合作情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('金地合作情况')
    },
    {
      label: '其他地产合作情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('其他地产合作情况')
    },
    {
      label: '企业融资情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('企业融资情况')
    },
    {
      label: '实控人及其他关键人融资情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('实控人及其他关键人融资情况')
    },
    {
      label: '涉诉/纠纷情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('涉诉/纠纷情况')
    },
    {
      label: '同行评价',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        maxlength: 500,
        rows: 4,
        options: [
          { id: 1, name: 1233 }
        ]
      },
      span: 24,
      tag: companyEvaluation,
      isHidden: !checkList.includes('同行评价')
    },
    {
      label: '产品推介情况',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: '4'
      },
      span: 24,
      isHidden: !checkList.includes('产品推介情况')
    },
    {
      label: '其他访谈内容',
      prop: 'summaryNum',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24
    },
    {
      label: '访谈相关附件',
      prop: 'file',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      tag: InterviewAccessory,
      span: 24
    }
  ]
}
// 访谈详情-项目公司
export const interviewDetilsConfigs = (content, index) => {
  // 选中的访谈范围
  const checkList = content.interviewRecordData[index].checkList
  return [
    {
      label: '项目情况',
      prop: 'summaryNum1',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('项目情况')
    },
    {
      label: '销售情况',
      prop: 'summaryNum2',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('销售情况')
    },
    {
      label: '付款情况',
      prop: 'summaryNum3',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      isHidden: !checkList.includes('付款情况')
    },
    {
      label: '甲方评价',
      prop: 'summaryNum4',
      attrs: {
        placeholder: '请输入',
        maxlength: 500,
        rows: 4
      },
      span: 24,
      tag: companyEvaluation,
      isHidden: !checkList.includes('甲方评价')
    },
    {
      label: '其他访谈内容',
      prop: 'summaryNum5',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500,
        rows: 4
      },
      span: 24
    }
  ]
}
export const accessoryConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '120px'
    },
    {
      label: '文件类型',
      prop: 'fileType',
      width: '280px'
    },
    {
      label: '文件',
      prop: 'file'
    }
  ]
}
// 文件生成
export const otherFile = (content) => {
  console.log(content.fileFormData, 'content.fileFormData')
  const span = 12
  return [
    {
      label: '文件名称',
      prop: 'fileName',
      attrs: {
        placeholder: '请生成或上传文件',
        generatefileId: content.fileFormData.fileId
      },
      span,
      tag: fileUpdate,
      rules: [{ required: true, message: '请生成或上传文件', trigger: 'change' }],
      on: {
        generate: (data) => {
          content.generateFile('generate')
        },
        upload: (data) => {
          content.fileFormData.fileId = data.fileId
        },
        preview: (data) => {
          content.previewShow()
        },
        delFile: () => {
          content.delFile()
        }
      }
    }

  ]
}
// 基础信息
export const basicFromConfig = (content) => {
  /**
   * 供应商名字
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validatePassName = (rule, value, callback) => {
    const reg = !/^[\u4e00-\u9fa5a-zA-Z]/gi.test(value)
    if (!value) {
      return callback(new Error(`${rule.title}不能为空`))
    } else if (reg) {
      return callback(new Error(`${rule.title}只能输入中文和英文`))
    } else {
      callback()
    }
  }
  /**
   * 社会信用代码校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validatecreditCode = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入社会信用代码'))
    } else if (value.length < 18) {
      return callback(new Error('请输入正确统一社会信用代码'))
    } else {
      callback()
    }
  }
  /**
   * 工商注册日期校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validateregisterTime = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请选择工商注册日期'))
    } else if (content.baseQueryParas.foundTime && content.baseQueryParas.foundTime < value) {
      return callback(new Error('请选择正确时间并且工商注册日期不能大于成立时间'))
    } else {
      callback()
    }
  }
  /***
   * 成立时间校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validatefoundTime = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请选择成立时间'))
    } else if (content.baseQueryParas.registerTime && content.baseQueryParas.registerTime > value) {
      return callback(new Error('请选择正确时间并且成立时间不能小于工商注册日期'))
    } else {
      callback()
    }
  }
  /**
   * 输入简码校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validataEnterpriseShortCode = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入简码'))
    } else if (!/^[a-zA-Z]+$/mgi.test(value)) {
      return callback(new Error('简码只能输入英文'))
    } else {
      callback()
    }
  }
  /***
   * 简称校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validataEnterpriseShortName = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入简称'))
    } else if (!/[\u4e00-\u9fa5]/mgi.test(value)) {
      return callback(new Error('简称只能输入中文'))
    } else {
      callback()
    }
  }
  // 所属城市校验
  const validateLocationCity = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入所在城市'))
    } else if (Array.isArray(value) && !value[0]) {
      return callback(new Error('请输入所在城市'))
    } else {
      callback()
    }
  }
  // 公司电话校验
  const validatePhone = (rule, value, callback) => {
    const reg = /0\d{2,3}-\d{7,8}/
    const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[6]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (!value) {
      return callback(new Error('请输入公司电话'))
    } else if (reg.test(value) || phoneReg.test(value)) {
      return callback()
    } else {
      callback(new Error('请输入正确的公司电话'))
    }
  }

  return [
    {
      label: '供应商名称：',
      prop: 'enterpriseName',
      rules: [{
        required: true,
        title: '供应商名称',
        validator: validatePassName,
        trigger: ['change', 'blur']
      }],
      attrs: {
        placeholder: '请输入',
        enterpriseName: content.baseQueryParas.enterpriseName,
        keyId: content.baseQueryParas.keyId
      },
      span: 24,
      tag: SupperlierTesting,
      on: {
        getSupplier: (data) => {
          if (data) {
            // 检测成功数据回填
            if (data.companyInfo) {
              content.$set(data.companyInfo, 'locationCity', [
                data.companyInfo.manageProvince ? data.companyInfo.manageProvince : content.baseQueryParas.manageProvince,
                data.companyInfo.manageCity ? data.companyInfo.manageCity : content.baseQueryParas.manageCity
              ])
            }
            content.baseQueryParas = data.companyInfo ? data.companyInfo : content.baseQueryParas
          }
        }
      }
    },
    {
      label: '法人代表：',
      prop: 'legalRepresentative',
      rules: [{
        required: true,
        title: '法人代表',
        validator: validatePassName,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '成立时间：',
      prop: 'foundTime',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: 'el-date-picker',
      rules: [{ required: true, validator: validatefoundTime, trigger: 'blur' }],
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '所在城市：',
      prop: 'locationCity',
      rules: [{ required: true, validator: validateLocationCity, trigger: ['blur', 'change'] }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: SupplierCascader,
      attrs: {
        addressvalue: content.baseQueryParas,
        type: 'add',
        placeholder: '请选择',
        address: getDictLists('ZDZD0013')
      }
    },
    {
      label: '公司电话：',
      prop: 'phoneNo',
      rules: [{
        required: true,
        validator: validatePhone,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入',
        maxlength: 13
      }
    }, {
      label: '统一社会信用代码：',
      prop: 'creditCode',
      rules: [{
        required: true,
        validator: validatecreditCode,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        maxlength: 18,
        placeholder: '请输入'
      }
    }, {
      label: '公司地址：',
      prop: 'address',
      rules: [{
        required: false,
        message: '请输入公司地址',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 10, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '公司传真：',
      prop: 'fax',
      rules: [{
        required: false,
        message: '请输入',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        maxlength: 13,
        placeholder: '请输入'
      }
    }, {
      label: '工商注册日期：',
      prop: 'registerTime',
      rules: [{
        required: true,
        validator: validateregisterTime,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '注册资金(元)：',
      prop: 'registerMoney',
      tag: Amountinput,
      rules: [{
        required: true,
        message: '请输入注册资金',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入',
        type: 'G'
      }
    }, {
      label: '实控人：',
      prop: 'actualController',
      rules: [{
        required: false,
        message: '请输入实控人',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '简称：',
      prop: 'enterpriseShortName',
      rules: [{
        required: true,
        validator: validataEnterpriseShortName,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '简码：',
      prop: 'enterpriseShortCode',
      rules: [{
        required: true,
        validator: validataEnterpriseShortCode,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入',
        maxlength: 5
      },
      on: {
        input (data) {
          console.log(data.toUpperCase(), 111)
          content.baseQueryParas.enterpriseShortCode = content.baseQueryParas.enterpriseShortCode.toUpperCase()
        }
      }
    }
  ]
}
