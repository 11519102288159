//访谈相关附件
<template>
  <div>
    <!-- 添加附件  -->
    <div class="addBtn">
      <div class="addButton" @click="showSelect">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      class="main-page-table"
      :columns="accessoryTable"
      :showPage="false"
      :tableAttrs="{
        data: contractData,
        stripe: true
      }"
      ref="tableData"
      :webPage="false"
      :isCaculateHeight="false"
    >
      <template slot="index" slot-scope="scope">{{scope.$index+1}}</template>
      <template slot="fileType" slot-scope="scope">
        <BaseSelect v-model="scope.row.fileType" :option="fileTypeList" :selectedField="['id','name']"></BaseSelect>
      </template>
      <template slot="file" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group
          v-for="(item, index) in scope.row.fileListDetailVOS"
          :key="index"
          :item="item"
          :index="index"
          :scope="scope"
          @previewShow="openPreview"
          @Upload="Upload"
          @deleteTemplate="deleteTemplate"
          @dowload='dowload'
        />
      </template>
    </base-table>
    <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileName ? fileData.fileName.split('.').pop() : ''" :count="count" />
  </div>
</template>
<script>
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { accessoryConfig } from '../utils/make-config'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import PreView from '@/components/pre-view/pre-view.vue'
import FileButtonGroup from './file-button-group.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
export default {
  components: { BaseTable, BaseSelect, FileButtonGroup, PreView },
  data () {
    return {
      contractData: [],
      fileTypeList: [
        { name: '图片', id: 1 }
      ],
      count: 0,
      visible: false,
      fileData: { fileId: '', fileName: '' }
    }
  },
  computed: {
    accessoryTable () {
      return accessoryConfig(this)
    }
  },
  methods: {
    showSelect () {
      this.contractData.push({ fileListDetailVOS: [{ fileId: '0', fileName: '', addFile: true }] })
    },
    change () {

    },
    // 预览
    openPreview (data) {
      console.log(data)
      if (data) {
        this.fileData = data
        this.count++
      }
    },
    // 文件上传
    async Upload (param, row, index) {
      const res = await this.UploadFile(param)
      if (!res) {
        return
      }
      console.log(param)
      console.log(row, 'row')
      const data = [{
        businessId: row.businessId,
        fileId: res.keyId,
        fileListKeyId: row.keyId,
        fileName: res.fileName,
        keyId: row.keyIdF,
        documentType: '1002',
        documentName: row.documentName,
        documentId: row.documentId
      }]
      // fileListApi.saveFinFileListDetails(data).then((res) => {
      if (data) {
        this.success('文件上传成功')
        this.contractData[index].fileListDetailVOS = data
      }
      // })
    },
    async UploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g

      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize > 0) {
        return this.warning('文件需小于10M')
      }

      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 删除文件
    deleteTemplate (row, index, i) {
      // 没有上传文件的直接删除
      if (row.fileId === '0') {
        this.contractData[index].fileListDetailVOS = [{ fileId: '0', fileName: '', addFile: true }]
        return
      }
      this.$confirm('是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.contractData[index].fileListDetailVOS = [{ fileId: '0', fileName: '', addFile: true }]
        //   // 调用接口删除
        //   fileListApi.deleteFile([row.fileListDetailVOS[i].keyId]).then(res => {
        //     if (res.data) {
        //       this.success('删除成功')
        //       this.contractData[index].fileListDetailVOS.splice(i, 1)
        //       this.getTableData()
        //     } else {
        //       this.error('删除失败')
        //     }
        //   })
        })
        .catch(() => {})
    },
    // 文件下载
    dowload (data) {
      console.log(data, 'data')
      const fileId = data.currentFileId || data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.addBtn {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding-right: 19px;
  padding-top: 10px;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }

}
</style>
