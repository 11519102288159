<template>
  <div class="selectEnterprise">
    <BaseSelect v-bind="$attrs" v-on="$listeners" class="select"></BaseSelect>
    <i class="el-icon el-icon-office-building" @click="openEnterprise"></i>
    <add-enterprise :visible.sync="visible"></add-enterprise>
  </div>
</template>
<script>
import BaseSelect from '@/components/common/base-select/base-select.vue'
import AddEnterprise from './add-enterprise.vue'
// import ad
export default {
  data () {
    return {
      visible: false
    }
  },
  components: { BaseSelect, AddEnterprise },
  methods: {
    // 打开企业弹窗
    openEnterprise () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.selectEnterprise {
  display: flex;
  align-items: center;
  .select {
    flex: 1;
  }
  .el-icon-office-building {
    margin-left: 10px;
    font-size: 18px;
    color: #2862E7;
    cursor: pointer;
  }
}
</style>
