<template>
  <base-dialog
    :visible.sync="currentVisible"
    width="1200px"
    :showFooter="false"
    title="新增企业信息"
    class="addEnterprise"
  >
    <base-form
      :componentList="enterpriseConfig"
      :formAttrs="{
        model: baseQueryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      :showBtns="false"
      ref="formData">
    </base-form>
    <template slot="footer">
      <base-button label='保 存'></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { basicFromConfig } from '../utils/make-config'
export default {
  props: {
    visible: Boolean
  },
  components: {
    BaseDialog,
    BaseForm,
    BaseButton
  },
  data () {
    return {
      baseQueryParas: {}
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    enterpriseConfig () {
      return basicFromConfig(this)
    }
  }
}
</script>
<style lang="scss" scoped>
.addEnterprise {
  /deep/ .el-dialog{
    height: 600px !important;
  }
}
</style>
